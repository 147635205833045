import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/teacher",
      redirect: "/teacher/index",
    },
    {
      path: "/student",
      redirect: "/student/index",
    },
    {
      path: "/principal",
      redirect: "/principal/index",
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
      meta: {
        index: 1,
      },
    },
    // {
    //   path: "/test",
    //   name: "test",
    //   component: () =>
    //     import(/* webpackChunkName: "test" */ "@/views/Test.vue"),
    //   meta: {
    //     index: 1,
    //   },
    // },
    {
      path: "/prints",
      name: "prints",
      component: () =>
        import(/* webpackChunkName: "prints" */ "@/views/prints/Prints.vue"),
      meta: {
        index: 1,
      },
    },

    //student
    {
      path: "/student/index",
      name: "studentIndex",
      component: () =>
        import(
          /* webpackChunkName: "studentIndex" */ "@/views/student/Index.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/student/info",
      name: "studentInfo",
      component: () =>
        import(
          /* webpackChunkName: "studentInfo" */ "@/views/student/Info.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/student/case/list",
      name: "scList",
      component: () =>
        import(
          /* webpackChunkName: "scList" */ "@/views/student/case/list.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/case/do",
      name: "scDo",
      component: () =>
        import(/* webpackChunkName: "scList" */ "@/views/student/case/do.vue"),
      meta: {
        index: 3,
      },
    },
    {
      path: "/student/wrong/list",
      name: "wrList",
      component: () =>
        import(
          /* webpackChunkName: "wrList" */ "@/views/student/wrong/List.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/wrong/wrDetail",
      name: "wrDetail",
      component: () =>
        import(
          /* webpackChunkName: "wrDetail" */ "@/views/student/wrong/WrongDetail.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/wrong/preview",
      name: "wrPreview",
      component: () =>
        import(
          /* webpackChunkName: "wrPreview" */ "@/views/student/wrong/PreviewWrong.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/selfEval",
      name: "selfEval",
      component: () =>
        import(
          /* webpackChunkName: "selfEval" */ "@/views/student/SelfEval.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/caseRes",
      name: "caseRes",
      component: () =>
        import(
          /* webpackChunkName: "caseRes" */ "@/views/student/case/CaseRes.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/teacherRemark",
      name: "teacherRemark",
      component: () =>
        import(
          /* webpackChunkName: "teacherRemark" */ "@/views/student/case/TeacherRemark.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/student/studyAnalysis",
      name: "studyAnalysis",
      component: () =>
        import(
          /* webpackChunkName: "studyAnalysis" */ "@/views/student/studyAnalysis/index.vue"
        ),
      meta: {
        index: 1,
      },
    },
	{
	  path: "/student/news",
	  name: "studentNews",
	  component: () =>
	    import(
	      /* webpackChunkName: "studyAnalysis" */ "@/views/student/news/index.vue"
	    ),
	  meta: {
	    index: 1,
	  },
	},

    //teacher
    {
      path: "/teacher/index",
      name: "teacherIndex",
      component: () =>
        import(
          /* webpackChunkName: "teacherIndex" */ "@/views/teacher/Index.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/case/list",
      name: "teacherCaseList",
      component: () =>
        import(
          /* webpackChunkName: "teacherCaseList" */ "@/views/teacher/Workbench/Case.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/teacher/settingBasis",
      name: "settingBasis",
      component: () =>
        import(
          /* webpackChunkName: "settingBasis" */ "@/views/teacher/Workbench/CaseBasisInfo.vue"
        ),
      meta: {
        index: 3,
      },
    },
    {
      path: "/teacher/caseContent",
      name: "caseContent",
      component: () =>
        import(
          /* webpackChunkName: "settingBasis" */ "@/views/teacher/Workbench/CaseContent.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/awaitCreate",
      name: "awaitCreate",
      component: () =>
        import(
          /* webpackChunkName: "awaitCreate" */ "@/views/teacher/Workbench/AwaitCreate.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/CaseSis",
      name: "teacherCaseSis",
      component: () =>
        import(
          /* webpackChunkName: "teacherCaseSis" */ "@/views/teacher/Workbench/CaseSis.vue"
        ),
      meta: {
        index: 1,
      },
      children: [
        {
          path: "/teacher/CaseSis/studentListAndData",
          name: "studentListData",
          component: () =>
            import(
              /* webpackChunkName: "studentListData" */ "@/views/teacher/Workbench/CaseSisChild.vue"
            ),
        },
      ],
    },
    {
      path: "/teacher/studentwork",
      name: "teacherStudentwork",
      component: () =>
        import(
          /* webpackChunkName: "teacherStudentwork" */ "@/views/teacher/Workbench/StudentWork.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/userinfo",
      name: "teacherInfo",
      component: () =>
        import(
          /* webpackChunkName: "teacherInfo" */ "@/views/teacher/User/Info.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/studentEval",
      name: "studentEval",
      component: () =>
        import(
          /* webpackChunkName: "studentEval" */ "@/views/teacher/Workbench/StudentSelfEval.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/teacher/fastReview",
      name: "fastReview",
      component: () =>
        import(
          /* webpackChunkName: "fastReview" */ "@/views/teacher/Workbench/FastReview.vue"
        ),
      meta: {
        index: 3,
      },
    },
    {
      path: "/teacher/caseDetail",
      name: "caseDetail",
      component: () =>
        import(
          /* webpackChunkName: "caseDetail" */ "@/views/teacher/Workbench/CaseDetail.vue"
        ),
      meta: {
        index: 3,
      },
    },
    {
      path: "/teacher/classManagement",
      name: "classManagement",
      component: () =>
        import(
          /* webpackChunkName: "classManagement" */ "@/views/teacher/ClassManagement/ClassMana.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/noticeManagement",
      name: "noticeManagement",
      component: () =>
        import(
          /* webpackChunkName: "noticeManagement" */ "@/views/teacher/NoticeManagement/List.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/noticeManagement/edit",
      name: "noticeManagementEdit",
      component: () =>
        import(
          /* webpackChunkName: "noticeManagementEdit" */ "@/views/teacher/NoticeManagement/Edit.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/studentList",
      name: "studentList",
      component: () =>
        import(
          /* webpackChunkName: "studentList" */ "@/views/teacher/ClassManagement/StudentList.vue"
        ),
      meta: {
        index: 3,
      },
    },
    {
      path: "/teacher/caseDataSis",
      name: "caseDataSis",
      component: () =>
        import(
          /* webpackChunkName: "caseDataSis" */ "@/views/teacher/Workbench/CaseDataSis.vue"
        ),
      meta: {
        index: 3,
      },
    },
    {
      path: "/teacher/AnaTeaching",
      name: "AnaTeaching",
      redirect: "/teacher/AnaTeaching/caseDataAna",
      component: () =>
        import(
          /* webpackChunkName: "AnaTeaching" */ "@/views/teacher/AnalysisOfTeaching/index.vue"
        ),
      meta: {
        index: 1,
      },
      children: [
        {
          path: "/teacher/AnaTeaching/caseDataAna",
          name: "caseDataAna",
          component: () =>
            import(
              /* webpackChunkName: "caseDataAna" */ "@/views/teacher/AnalysisOfTeaching/ChildRouter/CaseDataAna.vue"
            ),
        },
        {
          path: "/teacher/AnaTeaching/classDataAna",
          name: "classDataAna",
          component: () =>
            import(
              /* webpackChunkName: "classDataAna" */ "@/views/teacher/AnalysisOfTeaching/ChildRouter/ClassDataAna.vue"
            ),
        },
      ],
    },
    {
      path: "/teacher/questionAnswerChart",
      name: "questionAnswerChart",
      component: () =>
        import(
          /* webpackChunkName: "questionAnswerChart" */ "@/views/teacher/Workbench/QuestionAnswerChar.vue"
        ),
      meta: {
        index: 4,
      },
    },
    {
      path: "/teacher/playVideo",
      name: "playVideo",
      component: () =>
        import(
          /* webpackChunkName: "playVideo" */ "@/views/teacher/Workbench/PlayVideo.vue"
        ),
      meta: {
        index: 4,
      },
    },
    {
      path: "/teacher/printCenter",
      name: "printCenter",
      component: () =>
        import(
          /* webpackChunkName: "printCenter" */ "@/views/teacher/PrintCenter/index.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/printFile",
      name: "printFile",
      redirect: "/teacher/printFile/selectFile",
      component: () =>
        import(
          /* webpackChunkName: "printFile" */ "@/views/teacher/PrintCenter/PrintFile.vue"
        ),
      meta: {
        index: 2,
      },
      children: [
        {
          path: "/teacher/printFile/selectFile",
          name: "selectFile",
          component: () =>
            import(
              /* webpackChunkName: "selectFile" */ "@/views/teacher/PrintCenter/SelectFile.vue"
            ),
        },
        {
          path: "/teacher/printFile/settingInfo",
          name: "settingInfo",
          component: () =>
            import(
              /* webpackChunkName: "settingInfo" */ "@/views/teacher/PrintCenter/SettingInfo.vue"
            ),
        },
        {
          path: "/teacher/printFile/creating",
          name: "creating",
          name: "creating",
          component: () =>
            import(
              /* webpackChunkName: "creating" */ "@/views/teacher/PrintCenter/Creating.vue"
            ),
        },
      ],
    },
    {
      path: "/teacher/PrintReview",
      name: "PrintReview",
      component: () =>
        import(
          /* webpackChunkName: "PrintReview" */ "@/views/teacher/PrintCenter/PrintReview.vue"
        ),
      meta: {
        index: 1,
      },
    },
    {
      path: "/teacher/morePrintData",
      name: "morePrintData",
      component: () =>
        import(
          /* webpackChunkName: "morePrintData" */ "@/views/teacher/PrintCenter/MoreData.vue"
        ),
      meta: {
        index: 2,
      },
    },
    {
      path: "/teacher/morePrintFile",
      name: "morePrintFile",
      component: () =>
        import(
          /* webpackChunkName: "morePrintFile" */ "@/views/teacher/PrintCenter/MoreFile.vue"
        ),
      meta: {
        index: 2,
      },
    },

    //principal
    {
      path: "/principal/index",
      name: "principalIndex",
      component: () =>
        import(
          /* webpackChunkName: "principalIndex" */ "@/views/principal/Index.vue"
        ),
      meta: {
        index: 1,
      },
    },
  ],
});

export default router;
