export default {
  addCart(state, payload) {
    state.cartCount = payload.count;
  },

  changeNoStr(state, payload) {
    state.noStr = payload;
  },

  changeSettingInfo(state, payload) {
    state.settingInfo = payload;
  },
};
