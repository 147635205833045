const config = {
  isDebug: false,
  domain: {
    fileHost: "https://view.officeapps.live.com/op/embed.aspx?src=",

    imgHost: "https://api-dby.ldbzx.cn",
    main:
      process.env.NODE_ENV == "development"
        ? "http://192.168.124.115:9527"
        : "https://api-dby.ldbzx.cn/index.php",
  },
};
export default config;

//http://api.ldbzx.com:8081
//https://test-api-dby.ldbzx.cn/index.php
//https://api-dby.ldbzx.cn/index.php
