export default {
  async updateCart(ctx) {
    const { data } = [];
    ctx.commit("addCart", {
      count: data.length || 0,
    });
  },

  async onComitPrints({ commit }) {
    commit("changeprintsInfo", { name: "jojo" });
  },
};
