<template>
  <div id="app">
    <router-view class="router-view" v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { getLocal } from "@/common/js/utils";
export default {
  setup() {
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };

    const LOGIN_PAGE_NAME = "login";

    const router = useRouter();
    const state = reactive({
      transitionName: "slide-left",
    });
    router.beforeEach((to, from, next) => {
      if (to.meta.index > from.meta.index) {
        state.transitionName = "slide-left"; // 向左滑动
      } else if (to.meta.index < from.meta.index) {
        // 由次级到主级
        state.transitionName = "slide-right";
      } else {
        state.transitionName = ""; // 同级无过渡效果
      }

      const token = getLocal("ba-user-token");
      const userInfoJson = getLocal("userInfo");
      const userInfo = JSON.parse(userInfoJson);
      //跳过登录路由
      const passLoginRoute = ["test", "prints"];
      //角色路由
      const studentRoute = [
        "studentIndex",
        "scList",
        "scDo",
        "studentInfo",
        "wrList",
        "wrDetail",
        "wrPreview",
        "selfEval",
        "caseRes",
        "teacherRemark",
        "studyAnalysis",
		"studentNews",
      ];
      const teacherRoute = [
        "teacherIndex",
        "teacherCaseList",
        "settingBasis",
        "caseContent",
        "teacherAIaddCase",
        "teacherCaseSis",
        "teacherStudentwork",
        "teacherInfo",
        "awaitCreate",
        "studentEval",
        "fastReview",
        "studentListData",
        "caseDetail",
        "classManagement",
        "noticeManagement",
        "noticeManagementEdit",
        "studentList",
        "AnaTeaching",
        "caseDataAna",
        "classDataAna",
        "caseDataSis",
        "questionAnswerChart",
        "playVideo",
        "printCenter",
        "selectFile",
        "settingInfo",
        "PrintReview",
        "creating",
        "morePrintData",
        "morePrintFile",
      ];
      const principalRoute = ["principalIndex"];

      if (!token) {
        if (to.name !== LOGIN_PAGE_NAME && !passLoginRoute.includes(to.name)) {
          // 没有登录且路由不是登录页，则跳转到登录页
          next({
            name: LOGIN_PAGE_NAME, // 跳转到登录页
          });
        } else {
          // 没有登录且路由是登录页，则直接跳转
          next();
        }
      } else {
        if (to.name === LOGIN_PAGE_NAME) {
          next({
            name: userInfo.roleType + "Index", // 跳转到角色首页
          });
        } else {
          // 已登录且路由不是登录页，则验证角色页面权限
          if (
            userInfo.roleType == "student" &&
            !studentRoute.includes(to.name)
          ) {
            Toast.fail("没有权限！");
            // next({
            // 	name: LOGIN_PAGE_NAME // 跳转到登录页
            // })
          } else if (
            userInfo.roleType == "teacher" &&
            !teacherRoute.includes(to.name)
          ) {
            Toast.fail("没有权限！");
            // next({
            // 	name: LOGIN_PAGE_NAME // 跳转到登录页
            // })
          } else if (
            userInfo.roleType == "principal" &&
            !principalRoute.includes(to.name)
          ) {
            Toast.fail("没有权限！");
            // next({
            // 	name: LOGIN_PAGE_NAME // 跳转到登录页
            // })
          } else {
            next();
          }
        }
      }
    });

    router.afterEach(() => {
      //跳转回到页面顶部
      window.scrollTo(0, 0);
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less">
html,
body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f7f4f4;
}
#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

.router-view {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.van-badge--fixed {
  z-index: 1000;
}
</style>
