import { createApp } from "vue";
import {
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Image as VanImage,
  Toast,
  Notify,
  Skeleton,
  ActionSheet,
  Grid,
  GridItem,
  Circle,
  Search,
  Collapse,
  CollapseItem,
  NavBar,
  DatetimePicker,
  Picker,
  Uploader,
  Badge,
  DropdownMenu,
  DropdownItem,
  Popover,
  Progress,
  ImagePreview,
  RadioGroup,
  Radio,
  Pagination,
  Tag,
  Empty,
  CountDown,
  Step,
  Steps,
  Calendar,
} from "vant";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import config from "./utils/config";
import "lib-flexible/flexible";
import "vant/lib/index.css"; // 全局引入样式

const app = createApp(App); // 创建实例

// 全局过滤器
app.config.globalProperties.$filters = {
  prefix(url) {
    if (url && url.startsWith("http")) {
      return url;
    } else {
      url = config.domain.main + url;
      return url;
    }
  },
};

app
  .use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(VanImage)
  .use(Notify)
  .use(ActionSheet)
  .use(Grid)
  .use(GridItem)
  .use(Circle)
  .use(Search)
  .use(Collapse)
  .use(CollapseItem)
  .use(NavBar)
  .use(DatetimePicker)
  .use(Picker)
  .use(Uploader)
  .use(Badge)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Popover)
  .use(Progress)
  .use(ImagePreview)
  .use(RadioGroup)
  .use(Radio)
  .use(Pagination)
  .use(Tag)
  .use(CountDown)
  .use(Empty)
  .use(Step)
  .use(Steps)
  .use(Calendar);
app.use(router);
app.use(store);

app.mount("#app");
